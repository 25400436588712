.footerLicense {
  color: #fff;
  font-size: 14px;
}

.footerLicense-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  gap: 0 18px;
  li {
    height: 34px;
  }
  img {
    width: auto;
    height: 100%;
  }
}
.footerLicense-text {
  width: 100%;
  font-style: inherit;
  color: inherit;
  text-align: center;
  p {
    margin-top: 12px;
  }
}

.footerLicense-hiddenText {
  width: 100%;
  text-align: center;
  &.is-open {
    .footerLicense-hiddenText__inner {
      display: block;
    }
    button::after {
      transform: rotate(180deg);
    }
  }
  button {
    background: 0 0;
    border: none;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    color: inherit;
    &::after {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Im05LjAyNCAxMSA1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTFaIiBzdHlsZT0iIi8+PC9zdmc+");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 22px;
      flex-shrink: 0;
      margin-left: 2px;
      transition: transform .2s;
      display: block;
      filter: invert(95%) sepia(0%) saturate(19%) hue-rotate(355deg) brightness(104%) contrast(106%);
    }
  }
  &__inner {
    display: none;
  }
  p {
    margin-top: 12px;
  }
}
